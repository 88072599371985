import React from "react";
import StyledText, {TextStyles} from "../../../typography";
import * as styles from "../../../../styles/components/news/title.module.scss"
import {snakeCaseString} from "../../../../helpers/snake-case-string";

const Title = ({children, node, ...res}: any) => {
    const isMainTitle = node.tagName === "h1" || node.tagName === "h2";
    const id = snakeCaseString(children ? children[0] : '');
    return (
        <div className={`${styles.container} ${isMainTitle ? styles.mainTitle : ''}`}>
            <div className={styles.square}/>
            <StyledText
                id={id}
                tag={isMainTitle ? 'h2' : node.tagName}
                variant={isMainTitle ? TextStyles.h2Caps : TextStyles.bodyTextBold}
            >
                <a href={'#' + id}>{children}</a>
            </StyledText>
        </div>
    )
}

export default Title;
