import * as Scroll from 'react-scroll';

const scroller = Scroll.scroller;

export function scrollToAnchor(anchor: Location | string, offset = 0) {
    // Check for location so build does not fail
    let id = null;
    if (typeof anchor === "string") {
        id = anchor;
    } else if (anchor && anchor.hash) {
        const hash = decodeURIComponent( anchor.hash )
        id = hash.slice(1)
    }

    if(id) {
        scroller.scrollTo(id, {
            duration: 1500,
            smooth: true,
            container: id,
            offset
        });
    }

}
