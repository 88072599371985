import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../store/reducers";
import {NewsPageModel} from "../../store/models/news-page";
import {useActions} from "../../store/hooks";
import * as styles from "../../styles/components/news/news-next-article.module.scss";
import NewsItem from "./NewsItem";
import StyledText, {TextStyles} from "../typography";
import {CategoryLabelsModel} from "../../store/models/category-labels";

type NewsNextArticleProps = {
    title: string | null,
    currentSlug: string,
    categoryLabelsModel: CategoryLabelsModel
}

export const NewsNextArticle = (props: NewsNextArticleProps) => {
    const { title, currentSlug, categoryLabelsModel } = props;
    const news = useSelector((state: RootState) => state.newsStore.news);
    const {fetchNewsStore, setNewsQuery} = useActions();

    useEffect(() => {
        setNewsQuery({
            sort: {
                createdDate: 'DESC'
            },
            pagination: {
                page: 1,
                pageSize: 3
            },
            filters: { slug: {$ne: currentSlug}}
        })

        fetchNewsStore();
    }, [currentSlug])

    return (
        <div>
            <StyledText variant={TextStyles.h2}>
                {title}
            </StyledText>
            <div className={styles.grid}>
                {news && news.map((item, index) => (
                    <article key={index}>
                        <NewsItem newsItem={item} categoryLabelsModel={categoryLabelsModel}/>
                    </article>
                ))}
            </div>
        </div>
    )
}
