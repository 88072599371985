import React, {useEffect} from "react";
import {Layout} from "../components/Layout";
import * as styles from "../styles/pages/news-post.module.scss"
import NewsNav from "../components/news/NewsNav";
import {NewsPostModel, NewsPostPageModel} from "../store/models/news-post-page";
import NewsPostHeader from "../components/news/NewsPostHeader";
import {BodyFactory} from "../components/news/body/BodyFactory";
import {NewsNextArticle} from "../components/news/NewsNextArticle";
import {scrollToAnchor} from "../helpers/scroll-to-anchor";
import SEO from "../components/SEO";
import StrapiClient, {StrapiResponse, StrapiSingleTypes} from "../strapi-client/strapi-client";
import {MenuModel} from "../store/models/menu";
import {CategoryLabelsModel} from "../store/models/category-labels";
import {GetServerDataProps, GetServerDataReturn} from "gatsby";

type NewsPostPageProps = {
    serverData: {
        menu: StrapiResponse,
        page: StrapiResponse,
        post: StrapiResponse,
        categoryLabels: StrapiResponse
    },
    location: any
}

const NewsPostPage = (props: NewsPostPageProps) => {
    const {location} = props;

    const slug: string = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    const newsPostPageModel: NewsPostPageModel = NewsPostPageModel.fromJSON(props.serverData.page);
    const menuModel: MenuModel = MenuModel.fromJSON(props.serverData.menu);
    const post: NewsPostModel = NewsPostModel.fromJSON(props.serverData.post);
    const categoryLabelsModel: CategoryLabelsModel = CategoryLabelsModel.fromJSON(props.serverData.categoryLabels);

    useEffect(() => {
        scrollToAnchor(location);
    }, [])

    return (
        <Layout menu={menuModel}>
            <SEO
                seo={post.seo}
            />
            <NewsNav
                title={newsPostPageModel.title}
                category={''}
                isPost
                categoryLabels={categoryLabelsModel}
            />
            <div className={styles.grid}>
                <div>
                    <NewsPostHeader
                        post={post}
                        location={location}
                        categoryLabelsModel={categoryLabelsModel}
                    />
                </div>
                <main>
                    {post?.body?.map((item) => (
                        <BodyFactory item={item}/>
                    ))}
                </main>
            </div>
            <NewsNextArticle
                title={newsPostPageModel.nextArticlesLabel}
                currentSlug={slug}
                categoryLabelsModel={categoryLabelsModel}
            />
        </Layout>
    )
}

export default NewsPostPage;

export async function getServerData(context: GetServerDataProps): GetServerDataReturn  {
    try {
        const slug = context.params?.id as string;
        const locale: string = context.pageContext.locale as string;

        if (slug) {
            const strapi = new StrapiClient(locale);
            const page = await strapi.getSingleType(StrapiSingleTypes.newsPostPage);
            const menu = await strapi.getSingleType(StrapiSingleTypes.menu);
            const post = await strapi.getPost(slug);
            const categoryLabels = await strapi.getSingleType(StrapiSingleTypes.categoryLabels);

            return {
                props: {
                    page,
                    menu,
                    post,
                    categoryLabels
                },
            }
        } else {
            return {
                status: 500,
                headers: {},
                props: {}
            }
        }
    } catch (error: any) {
        if (error && error.response && error?.response.status === 404) {
            return {
                status: 404,
            }
        }
        return {
            status: 500,
            headers: {},
            props: {}
        }
    }
}
