import React, {useEffect, useState} from "react";
import * as styles from "../../styles/components/news/news-post-header.module.scss"
import StyledText, {TextStyles} from "../typography";
import SocialButton from "../common/button/SocialButton";
import {IconFacebook, IconIn, IconInstagram, IconTelegram, IconTwitter} from "../svg";
import {useHeadingsData} from "../../helpers/use-headings-data ";
import {NewsPostModel, NewsPostPageModel} from "../../store/models/news-post-page";
import {useSelector} from "react-redux";
import {RootState} from "../../store/reducers";
import {CategoryLabelsModel} from "../../store/models/category-labels";
import {useIntersectionObserver} from "../../helpers/use-intersection-observer";

import * as Scroll from 'react-scroll';

const scroller = Scroll.scroller;

export function scrollToTitle(id: string): void {
    scroller.scrollTo(id, {
        duration: 1500,
        smooth: true,
        offset: -190,
        container: id
    });
}

export type NewsPostHeaderProps = {
    location: any,
    categoryLabelsModel: CategoryLabelsModel,
    post: NewsPostModel,
}

const NewsPostHeader = (props: NewsPostHeaderProps) => {
    const { location, categoryLabelsModel, post} = props;

    const {nestedHeadings} = useHeadingsData(post.slug || '');
    const [activeId, setActiveId] = useState();
    useIntersectionObserver(setActiveId, post.slug || '');

    useEffect(() => {
        setActiveId(nestedHeadings.length > 0 ? nestedHeadings[0].id : null )
    },[nestedHeadings.length])

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <StyledText variant={TextStyles.bodyText2}>
                    {categoryLabelsModel.categories &&
                     categoryLabelsModel.categories.find((e) => (e.category === post.category))?.label}

                </StyledText>
                <StyledText variant={TextStyles.bodyText3} className={styles.date}>
                    {post.createdDate?.formatted || post.createdAt?.formatted}
                </StyledText>
            </div>
            <StyledText variant={TextStyles.h1}>
                {post.title}
            </StyledText>

            <div className={styles.contacts}>
                <SocialButton href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`} color='gray'>
                    <IconFacebook/>
                </SocialButton>
                <SocialButton href={`https://telegram.me/share/url?url=${location.href}`} color='gray'>
                    <IconTelegram/>
                </SocialButton>
                <SocialButton href={`https://twitter.com/intent/tweet?url=${location.href}`} color='gray'>
                    <IconTwitter />
                </SocialButton>
                <SocialButton href={`http://www.linkedin.com/shareArticle?mini=true&url=${location.href}`} color='gray'>
                    <IconIn/>
                </SocialButton>
            </div>

            <ul>
                {nestedHeadings.map((item: any) => (
                    <li className={`${styles.listItemTitle} ${activeId == item.id ? styles.listItemTitleActive : '' }`} key={item.id}>
                        <a
                            onClick={() => scrollToTitle(item.id)}
                            className={`t-body-content`}
                        >
                            {item.title}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default NewsPostHeader
