import React from "react";
import * as styles from "../../../../styles/components/news/images-grid.module.scss";
import {BodyFactoryProps} from "../BodyFactory";
import ImageContainer from "../../../ImageContainer";
import ProgressiveImage from "../../../common/ProgressiveImage";

export const ImagesGrid = (props: BodyFactoryProps) => {
    const { images } = props?.item || null;

    return (
        <div className={styles.grid}>
            {images && images.map((image) => (
                <ImageContainer aspectRatio='3/2' img={image} />
            ))}
        </div>
    )
}
