import React, {useEffect} from "react";
import ReactPlayer from 'react-player'
import {BodyFactoryProps} from "../BodyFactory";
import * as styles from "../../../../styles/components/news/player.module.scss";

export const Player = (props: BodyFactoryProps) => {
    const {videoUrl, video} = props?.item
    const canPlay = ReactPlayer.canPlay(videoUrl || video?.fullUrl || '')

    useEffect(() => {
        console.error("React-player can't play video")
    }, [])

    return (
        canPlay ?
            <div className={styles.container}>
                <ReactPlayer
                    className={styles.player}
                    controls
                    url={videoUrl || video?.fullUrl}
                    width='100%'
                />
            </div>
        : null
    )
}