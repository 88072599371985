import React from "react";
import StyledText, {TextStyles} from "../../../typography";
import {BodyFactoryProps} from "../BodyFactory";
import * as styles from "../../../../styles/components/news/imageFullSize.module.scss"
import ProgressiveImage from "../../../common/ProgressiveImage";

export const ImageFullSize = (props: BodyFactoryProps) => {
    const { image, subtitle } = props?.item.imageFullSize || null;

    return (
        <div>
            {image && (
                <ProgressiveImage
                    image={image}
                    className={styles.imageContainer}
                />
            )}

            <StyledText variant={TextStyles.bodyText3}>
                {subtitle}
            </StyledText>
        </div>
    )
}
