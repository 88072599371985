import React from "react";
import {NewsPostBody, NewsPostBodyType} from "../../../store/models/news-post-page";
import {ImagesGrid} from "./components/ImagesGrid";
import {RichText} from "./components/RichText";
import {ImageFullSize} from "./components/ImageFullSize";
import {PostButton} from "./components/PostButton";
import {Player} from "./components/Player";

const bodyComponents: any = {
    [NewsPostBodyType.TEXT]: RichText,
    [NewsPostBodyType.IMAGES_GRID]: ImagesGrid,
    [NewsPostBodyType.IMAGE_FULL_SIZE]: ImageFullSize,
    [NewsPostBodyType.BUTTON]: PostButton,
    [NewsPostBodyType.VIDEO]: Player,
}

export const createInstance = (props: BodyFactoryProps | null) => {
    let component;
    if(props?.item.type) {
        component = bodyComponents[props.item.type];
    }

    if (!component) {
        return null;
    } else {
        return React.createElement(component, props);
    }
};

export type BodyFactoryProps = {
    item: NewsPostBody
}

export const BodyFactory = (props: BodyFactoryProps) => {
    let instance = createInstance(props);

    if (!instance) {
        console.warn("Debug: There is no factory instance with provider config!");
        return null;
    }

    return (
        <div>
            {instance}
        </div>
    );
}