import Text from "../../../markdown/Text";
import Link from "../../../markdown/Link";
import Title from "./Title";
import {Quote} from "./Quote";
import {List} from "./List";

const newsPostMarkdown = {
    h1: Title,
    h2: Title,
    h3: Title,
    h4: Title,
    h5: Title,
    h6: Title,
    p: Text,
    a: Link,
    ol: List,
    ul: List,
    blockquote: Quote,
}

export default newsPostMarkdown