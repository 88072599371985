import React from "react";
import ReactMarkdown from "react-markdown";
import {BodyFactoryProps} from "../BodyFactory";
import newsPostMarkdown from "./NewsPostMarkdown";
import * as styles from '../../../../styles/pages/news-post.module.scss'

export const RichText = (props: BodyFactoryProps) => {
    return (
        <ReactMarkdown
            components={newsPostMarkdown}
            className={styles.richTextContainer}
        >{props?.item.text || ''}</ReactMarkdown>
    )
}