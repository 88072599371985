// extracted by mini-css-extract-plugin
export var accentColor = "#e10600";
export var container = "quote-module--container--71c03";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var icon = "quote-module--icon--67a00";
export var primaryColor = "#fff";
export var secondaryColor = "#000";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";