import React from "react";
import Button from "../../../common/button/Button";
import {BodyFactoryProps} from "../BodyFactory";
import StyledText, {TextStyles} from "../../../typography";
import * as styles from '../../../../styles/pages/news-post.module.scss'

export const PostButton = (props: BodyFactoryProps) => {
    const {label, url} = props.item.button;

    return (
        <div className={styles.postButton}>
            <Button variant='outlined' color='secondary' href={url || '#'} externalLink={true}>
                <StyledText variant={TextStyles.button}>
                    {label}
                </StyledText>
            </Button>
        </div>
    )
}
