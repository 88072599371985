import {useEffect, useState} from "react";

const getNestedHeadings = (headingElements: any) => {
    const nestedHeadings: any[] = [];

    headingElements.forEach((heading: any, index: number) => {
        const { textContent: title, id } = heading;

        if (heading.nodeName === "H2") {
            nestedHeadings.push({ id, title });
        }
    });

    return nestedHeadings;
};

export const useHeadingsData = (slug: string) => {
    const [nestedHeadings, setNestedHeadings] = useState([]);

    useEffect(() => {
        const headingElements = Array.from(
            document.querySelectorAll("main h2")
        );

        const newNestedHeadings = getNestedHeadings(headingElements);
        // @ts-ignore
        setNestedHeadings(newNestedHeadings);
    }, [slug]);

    return { nestedHeadings };
};
