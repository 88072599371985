// extracted by mini-css-extract-plugin
export var accentColor = "#e10600";
export var container = "player-module--container--a695e";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var player = "player-module--player--04549";
export var primaryColor = "#fff";
export var secondaryColor = "#000";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";