// extracted by mini-css-extract-plugin
export var accentColor = "#e10600";
export var background = "news-post-header-module--background--88e2e";
export var contacts = "news-post-header-module--contacts--85457";
export var container = "news-post-header-module--container--3ae1b";
export var date = "news-post-header-module--date--c4847";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var listItemTitle = "news-post-header-module--listItemTitle--a7c05";
export var listItemTitleActive = "news-post-header-module--listItemTitleActive--e6533";
export var primaryColor = "#fff";
export var secondaryColor = "#000";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";
export var titleContainer = "news-post-header-module--titleContainer--04f5c";