import React from "react";
import * as styles from "../../../../styles/components/news/quote.module.scss";
import StyledText, {TextStyles} from "../../../typography";
import {IconQuotes} from "../../../svg";

export const Quote = ({children, node, ...res}: any) => {

    return (
        <div className={styles.container}>
            <IconQuotes className={styles.icon} />
            <StyledText tag='p' variant={TextStyles.h3}>
                {children.map((e: any) => {
                    if (typeof e === 'string') {
                        return e
                    } else {
                        return e.props.children
                    }
                })}
            </StyledText>
        </div>
    )
}