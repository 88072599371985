// extracted by mini-css-extract-plugin
export var accentColor = "#e10600";
export var container = "title-module--container--77f53";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var mainTitle = "title-module--mainTitle--3ab0d";
export var primaryColor = "#fff";
export var secondaryColor = "#000";
export var square = "title-module--square--1be34";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";